import React from "react"
import { Div, Text } from "atomize"
import CardHeader from "../common/CardHeader"
import ImageLazyLoad from "../common/molecules/ImageLazyLoad"

const PolaroidCard = () => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
  >
    <CardHeader
      image="/dealer-profile.svg"
      heading="Profile"
      padding="0.625rem 0.625rem"
    />
    <Div bg="yellow" rounded={{ b: "lg" }} d="flex" flexDir="column" p="30px">
      <Text
        textSize="display1"
        textTransform="uppercase"
        fontFamily="secondary"
        m={{ b: "30px" }}
      >
        Hi, I'M Princess Kim
      </Text>

      <Div d="flex" flexDir={{ lg: "row", xs: "column" }}>
        <Div
          transform="rotate(-2deg)"
          d="flex"
          flexDir="column"
          p={{ x: "20px", t: "20px", b: "15px" }}
          border="1px solid"
          borderColor="#F8D4E4"
          bg="#FFFFFF"
          shadow="7"
          m={{ r: { lg: "30px" }, b: { lg: "0px", xs: "30px" } }}
          className="polaroid-container"
        >
          <ImageLazyLoad
            bgImg="https://cdn.shopify.com/s/files/1/2229/0673/files/S1C1_4_3.jpg?v=1606089939"
            bgPos="center"
            bgSize="cover"
            p={{ b: "100%" }}
            transition
          />
          <Text
            textSize="title1"
            textTransform="uppercase"
            fontFamily="tertiary"
            m={{ t: "14px" }}
            style={{ alignSelf: "center" }}
            textAlign="center"
          >
            Princess Kim
          </Text>
        </Div>
        <Div d="flex" flexDir="column" fontFamily="primary" textSize="tiny">
          <Text textSize="header1" textWeight="bold" m={{ b: "20px" }}>
            "I'm a PRINCESS and you are not so there!"
          </Text>
          <Text m={{ b: "4px" }}>Female</Text>
          <Text m={{ b: "4px" }}>25 years old</Text>
          <Text m={{ b: "4px" }}>Berely Hills, Californai</Text>
          <Text m={{ b: "4px" }}>United States</Text>
        </Div>
      </Div>
    </Div>
  </Div>
)

export default PolaroidCard
