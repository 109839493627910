import React from "react"
import { Div, Text, Row, Col } from "atomize"
import CardHeader from "../common/CardHeader"
import TopPickCard from "./TopPickCard"

const TopPicks = () => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
  >
    <CardHeader
      image="/dealer-shop.svg"
      heading="Shop with me"
      padding="0.625rem 0.625rem"
    />
    <Div
      bg="yellow"
      rounded={{ b: "lg" }}
      d="flex"
      flexDir="column"
      p="30px"
      align="center"
    >
      <Row
        textAlign="center"
        m={{ b: "30px" }}
        p={{ b: "30px" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        <Col size="12">
          <Text
            textSize="header1"
            textTransform="uppercase"
            fontFamily="secondary"
          >
            Get 10% off and free shipping
          </Text>

          <Div textSize="body" m={{ t: "10px" }}>
            {/* <PortableText blocks={body} /> */}
            <Text
              style={{
                lineHeight: "24px",
              }}
            >
              Shop my favorite products below, plus get 10% off your first order
              and free shipping when you spend $30 or more.
            </Text>
          </Div>
        </Col>
      </Row>
      <Text
        m={{ b: "30px" }}
        textSize="header1"
        fontFamily="secondary"
        textTransform="uppercase"
      >
        My top picks
      </Text>
      <Div
        className="top-picks-container"
        m={{ b: "30px" }}
        p={{ b: "30px" }}
        border={{ b: "1px solid" }}
        borderColor="yellowPink"
      >
        {[1, 2, 3, 4].map(item => (
          <TopPickCard key={item} id={item} />
        ))}
      </Div>
      <Text textAlign="center">
        PS, I make a small commission on orders placed through my page.
      </Text>
    </Div>
  </Div>
)

export default TopPicks
