import { Div } from "atomize"
import { get } from "lodash"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import ls from "local-storage"
import { useMutation, useQuery } from "@apollo/client"

import { SEO } from "../components/common/SEO"
// import useEmergence from "../hooks/useEmergence"
import Layout from "../components/common/Layout"
import { getPageModules } from "../services/pageService"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import { saveCheckoutInLocalStorage } from "../services/cartService"
import {
  CHECKOUT_BY_ID,
  CHECKOUT_CREATE,
  TOGGLE_CART,
} from "../graphql/queries"
// import { DealerWrapper } from "../components/dealer"
import ModuleDealer from "../modules/ModuleDealer"

const Dealer = props => {
  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {},
  })

  const { data: checkoutData, refetch: refechCheckoutData } = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: { id: ls("lastCheckoutID") },
    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({ variables: { cartDrawerVisible: true } })
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      console.log(ls("lastCheckoutID"))
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: { id: ls("lastCheckoutID") },
      })
    },
  })
  //********************************** */

  // useEmergence()
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const { affiliate } = data
  const site = (data || {}).site
  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  Object.keys(affiliate).forEach(data => console.log(data))

  const pageTitle = affiliate.name
  return (
    <Layout site={site}>
      <SEO title={pageTitle} />
      <ModuleDealer affiliate={affiliate} />
      {/* <DealerWrapper affiliate={affiliate} /> */}
    </Layout>
  )
}

export default Dealer

export const query = graphql`
  query DealerTemplateQuery($id: String!, $slug: String!) {
    affiliate: sanityAffiliatePage(id: { eq: $id }) {
      name
      quote
      AffiliateProducts {
        description
        affiliateProduct {
          title
          price
          inventory_quantity
          featuredImage {
            asset {
              path
            }
          }
        }
      }
      head {
        image {
          asset {
            path
          }
        }
        alt
      }
    }
    junipProduct(remote_handle: { eq: $slug }) {
      id
      rating_average
      rating_count
      remote_id
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
