import React from "react"
import { Div, Text } from "atomize"

import ImageLazyLoad from "../common/molecules/ImageLazyLoad"

const TopPickCard = ({ id }) => {
  const handleMouseEnter = () => {
    const card = document.getElementById(`top-pick-img-${id}`)
    card.style["opacity"] = 0
    const hoverCard = document.getElementById(`top-pick-hover-${id}`)
    hoverCard.style["opacity"] = 1
  }
  const handleMouseLeave = () => {
    const card = document.getElementById(`top-pick-img-${id}`)
    card.style["opacity"] = 1
    const hoverCard = document.getElementById(`top-pick-hover-${id}`)
    hoverCard.style["opacity"] = 0
  }

  return (
    <Div
      textSize="body"
      d="flex"
      flexDir="column"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      cursor="pointer"
    >
      <Div
        rounded="default"
        d="flex"
        overflow="hidden"
        m={{ b: "20px" }}
        pos="relative"
      >
        <ImageLazyLoad
          id={`top-pick-img-${id}`}
          bgImg="https://cdn.sanity.io/images/bkrzj7um/production/bd7c2ee0367baaa470f2f87b3962c0a8d7ddbe11-1620x1620.jpg"
          bgPos="center"
          bgSize="cover"
          p={{ b: "100%" }}
          w="100%"
          transition
        />

        <Div
          id={`top-pick-hover-${id}`}
          transition
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
          }}
        >
          <Div
            bgImg="https://cdn.sanity.io/images/bkrzj7um/production/75cd91e8d00e7facca25fb3bfbafc0c6f23d44d0-1620x1620.jpg"
            p={{ b: "100%" }}
            bgSize="cover"
            bgPos="center"
          />
        </Div>
      </Div>
      <Text m={{ b: "10px" }} textWeight="bold">
        Sleep CBD Gummies
      </Text>
      <Text>
        "I was always afraid of doing my brows until I was introduced to Boy
        Brow, and my entire brow game changed. Instead of making your brows look
        perfect, it tames and fills them just enough to look perfectly imperfect
        (in only seven seconds!)."
      </Text>
    </Div>
  )
}

export default TopPickCard
