import React from "react"
import { Div, Text } from "atomize"
import CardHeader from "../common/CardHeader"

const DealerDetails = () => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
  >
    <CardHeader
      image="/dealer-details.svg"
      heading="Princess Kim Details"
      padding="0.625rem 0.625rem"
    />
    <Div
      bg="yellow"
      rounded={{ b: "lg" }}
      d="flex"
      flexDir="column"
      p="30px"
      color="blue"
      justify="flex-start"
    >
      <Div d="flex" align="flex-start" m={{ b: "8px" }}>
        <Text
          rounded="sm"
          p={{ x: "10px", y: "5px" }}
          m={{ r: "10px" }}
          bg="pink"
          minW="98px"
          textWeight="bold"
        >
          Dealer since
        </Text>
        <Text rounded="sm" p={{ x: "10px", y: "5px" }} bg="bg2" flexGrow="1">
          Jan 2021
        </Text>
      </Div>
      <Div d="flex" align="flex-start" m={{ b: "8px" }}>
        <Text
          rounded="sm"
          p={{ x: "10px", y: "5px" }}
          m={{ r: "10px" }}
          bg="pink"
          minW="98px"
          textWeight="bold"
        >
          Intrests
        </Text>
        <Text rounded="sm" p={{ x: "10px", y: "5px" }} bg="bg2" flexGrow="1">
          Baking sweets, kissing, swimming, skiing, dreaming, eating sweets, oh
          and cars!
        </Text>
      </Div>
      <Div d="flex" align="flex-start" m={{ b: "8px" }}>
        <Text
          rounded="sm"
          p={{ x: "10px", y: "5px" }}
          m={{ r: "10px" }}
          bg="pink"
          minW="98px"
          textWeight="bold"
        >
          Music
        </Text>
        <Text rounded="sm" p={{ x: "10px", y: "5px" }} bg="bg2" flexGrow="1">
          Fall Out Boy, Fergie
        </Text>
      </Div>
      <Div d="flex" align="flex-start" m={{ b: "8px" }}>
        <Text
          rounded="sm"
          p={{ x: "10px", y: "5px" }}
          m={{ r: "10px" }}
          bg="pink"
          minW="98px"
          textWeight="bold"
        >
          Movies
        </Text>
        <Text rounded="sm" p={{ x: "10px", y: "5px" }} bg="bg2" flexGrow="1">
          Clueless, The Notebook
        </Text>
      </Div>
      <Div d="flex" align="flex-start" m={{ b: "8px" }}>
        <Text
          rounded="sm"
          p={{ x: "10px", y: "5px" }}
          m={{ r: "10px" }}
          bg="pink"
          minW="98px"
          textWeight="bold"
        >
          Books
        </Text>
        <Text rounded="sm" p={{ x: "10px", y: "5px" }} bg="bg2" flexGrow="1">
          Clueless, The Notebook
        </Text>
      </Div>
    </Div>
  </Div>
)

export default DealerDetails
