import React from "react"
import { Div, Text, Anchor } from "atomize"

import CardHeader from "../common/CardHeader"

const DealerContact = () => (
  <Div
    rounded="lg"
    border="1px solid"
    borderColor="lightPink"
    shadow="1"
    m={{ b: "40px" }}
  >
    <CardHeader
      image="/dealer-contact.svg"
      heading="Contact Princess Kim"
      padding="0.625rem 0.625rem"
    />
    <Div
      bg="yellow"
      rounded={{ b: "lg" }}
      d="grid"
      flexDir="column"
      p="30px"
      style={{
        gridTemplateColumns: "1fr 1fr",
        gridGap: "20px",
      }}
    >
      <Anchor d="flex" align="center" target="_blank">
        <img
          src="/instaIcon.svg"
          style={{ display: "inline-block", marginRight: 10 }}
        />
        <Text textDecor="underline" style={{ color: "#283377" }}>
          Follow on Instagram
        </Text>
      </Anchor>
      <Anchor d="flex" align="center" target="_blank">
        <img
          src="/twitterIcon.svg"
          style={{ display: "inline-block", marginRight: 10 }}
        />
        <Text textDecor="underline" style={{ color: "#283377" }}>
          Follow on Twitter
        </Text>
      </Anchor>
      <Anchor d="flex" align="center" target="_blank">
        <img
          src="/youtubeIcon.svg"
          style={{ display: "inline-block", marginRight: 10 }}
        />
        <Text textDecor="underline" style={{ color: "#283377" }}>
          Follow on Youtube
        </Text>
      </Anchor>
      <Anchor d="flex" align="center" target="_blank">
        <img
          src="/tiktokIcon.svg"
          style={{ display: "inline-block", marginRight: 10 }}
        />
        <Text textDecor="underline" style={{ color: "#283377" }}>
          Follow on TikTok
        </Text>
      </Anchor>
    </Div>
  </Div>
)

export default DealerContact
