import React from "react"
import { Div } from "atomize"

import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import PolaroidCard from "../components/dealer/PolaroidCard"
import DealerDetails from "../components/dealer/DealerDetails"
import DealerContact from "../components/dealer/DealerContact"
import TopPicks from "../components/dealer/TopPicks"

export default function ModuleDealer(props) {
  console.log("DEALER DATE", props)
  return (
    <Div
      p={{
        t: { xs: "1.875rem", lg: "2.5rem" },
        b: { xs: "0", lg: "2.25rem" },
      }}
      className="bgblocks"
      data-bg="#FDEEFF"
    >
      <WebsiteContainer>
        <Div className="dealer-container">
          <Div flexGrow="1" m={{ r: { lg: "50px" } }}>
            <PolaroidCard />
            <DealerDetails />
            <Div d={{ xs: "none", lg: "block" }}>
              <DealerContact />
            </Div>
          </Div>
          <Div flexGrow="1">
            <TopPicks />
          </Div>
          <Div d={{ xs: "block", lg: "none" }}>
            <DealerContact />
          </Div>
        </Div>
      </WebsiteContainer>
    </Div>
  )
}
